import queryString from '@utils/queryString'

const API_URL = {
  ACCOUNTING: {
    DELETE_ACCOUNTING: id => `accounting/${id}/`,
    DELETE_ACCOUNTING_PERIOD: id => `accounting/accounting_periods/${id}/`,
    DELETE_ACCOUNTING_TAXCODE: id => `accounting/taxcode/${id}/`,
    GET_ACCOUNTING_LIST: org_pk => `accounting/${org_pk}/`,
    GET_ACCOUNTING_PERIODS_LIST: org_pk =>
      `accounting/accounting_periods/${org_pk}/`,
    GET_ACCOUNTING_TAXCODE_LIST: org_pk => `accounting/taxcode/${org_pk}/`,
    PATCH_ACCOUNTING: id => `accounting/${id}/`,
    PATCH_ACCOUNTING_PERIOD: id => `accounting/accounting_periods/${id}/`,
    PATCH_ACCOUNTING_TAXCODE: id => `accounting/taxcode/${id}/`,
    POST_ACCOUNTING_LIST: (org_pk, team_pk) =>
      `/accounting/${org_pk}/?team=${team_pk}/`,
    POST_ACCOUNTING_PERIODS_LIST: org_pk =>
      `accounting/accounting_periods/${org_pk}/`,
    POST_ACCOUNTING_TAXCODE_LIST: orgPk => `accounting/taxcode/${orgPk}/`
  },

  ACTIONS: {
    LIST: `actions/`
  },

  ALERTS: {
    GET_ALERTS: () => `alerts/`
  },

  AUTH: {
    ACTIVATION: 'auth/users/activation/',
    LOGIN: '/auth/jwt/create/',
    ME: 'profiles/user/',
    PATCH_USER: id => `auth/users/${id}/`,
    RESEND_VERIFICATION: 'auth/users/resend_activation/',
    RESET_PASSWORD: 'auth/users/reset_password/',
    RESET_PASSWORD_CONFIRM: 'auth/users/reset_password_confirm/',
    SET_PASSWORD: 'auth/users/set_password/',
    SIGNUP: 'auth/users/',
    TOKEN_REFRESH: 'token-refresh/',
    TOKEN_VERIFY: 'token-verify/'
  },

  BANKS: {
    DELETE_BANK_ID: id => `banks/${id}/`,
    DELETE_CONNECTED_USER: bankId =>
      `treasuries/connect/${bankId}/delete_user/`,
    DELETE_TRANSACTION_MATCHES: matchId =>
      `treasuries/transaction_matches/${matchId}/`,
    GET_ALL_BANKS: `banks/`,
    GET_LIST: () => `banks/`,
    GET_ALL_TRANSACTION_MATCHES: () => `treasuries/transaction_matches/`,
    GET_BANK_ID: id => `banks/${id}/`,
    GET_BANKS_LIST_PK: () => `banks/`,
    CONNECT_BANK: bankId => `treasuries/connect/${bankId}/`,
    MANAGE_BANK_CONNECTIONS: bankId =>
      `treasuries/manage_bank_connections/${bankId}/`,
    RECONNECT_BANK: bankId => `treasuries/reconnect/${bankId}/`,
    GET_TRANSACTION_MATCHES: transactionId =>
      `treasuries/transaction_matches/${transactionId}/`,
    PATCH_BANK_ID: id => `banks/${id}/`,
    PATCH_FORCE_SYNC: bankId => `treasuries/force_sync/${bankId}/`,
    PATCH_MATCH_TRANSACTION: matchId =>
      `treasuries/transaction_matches/${matchId}/`,
    POST_BANK: `banks/`,
    POST_BANK_LIST_PK: `banks/`,
    POST_MATCH_TRANSACTION: () => `treasuries/transaction_matches/`
  },

  BILLING: {
    GET_MANAGE_SUBSCRIPTION: (org_pk, customer_id, redirect = null) =>
      `billing/manage-subscription/${org_pk}/?customer_id=${customer_id}&redirect=${redirect}`,
    GET_SUBSCRIPTION: org_pk => `billing/subscription/${org_pk}/`,
    POST_CREATE_SUBSCRIPTION: (org_pk, redirect = null) =>
      `billing/create-subscription/${org_pk}/?redirect=${redirect}`
  },

  BILLING_ENTITIES: {
    DELETE_BILLING_ENTITY: id => `billing_entities/${id}/`,
    GET_BILLING_ENTITIES_LIST: `billing_entities/`,
    GET_BILLING_ENTITY: id => `billing_entities/${id}/`,
    PATCH_BILLING_ENTITY: id => `billing_entities/${id}/`
  },

  BUDGETS: {
    GET_BUDGETS_LIST_PK: `budgets/year/`,
    PATCH_BUDGET: id => `budgets/year/${id}/`
  },
  BUILDINGS: {
    GET_BUILDINGS: () => `buildings/`,
    GET_BUILDING: buildingId => `buildings/${buildingId}/`,
    POST_BUILDING: () => `buildings/`,
    PATCH_BUILDING: buildingId => `buildings/${buildingId}/`,
    DELETE_BUILDING: buildingId => `buildings/${buildingId}/`
  },
  CASHFLOW: {
    GET_METRICS: () => `metrics/cashflow_chart/`,
    GET_TRANSACTIONS: () => `treasuries/transactions/`,
    PATCH_GENERATE_MATCHES: () => `treasuries/generate_match/`,
    PATCH_TRANSACTION: transactionId =>
      `treasuries/transactions/${transactionId}/`
  },

  CATEGORIES: {
    GET_CATEGORIES: () => `categories/`
  },

  CITIES: {
    GET_CITIES: `projects/cities/`
  },

  COMMENTS: {
    DELETE_COMMENT: id => `comments/${id}/`,
    GET_ALL_COMMENTS: (type, object_type_pk) =>
      `comments/all/${type}/${object_type_pk}/`,
    PATCH_COMMENT: id => `comments/${id}/`,
    POST_COMMENT: (type, object_type_pk) =>
      `comments/all/${type}/${object_type_pk}/`
  },

  CONTACTS: {
    DELETE_CONTACT: id => `contacts/${id}/`,
    DELETE_CONTACTS_CATEGORY: id => `contacts/category/${id}/`,
    GET_CONTACT: id => `contacts/${id}/`,
    GET_CONTACTS_CATEGORIES_LIST: org_pk => `contacts/categories/${org_pk}/`,
    GET_CONTACTS_LIST_PK: () => `contacts/`,
    PATCH_CONTACTS_CATEGORY: id => `contacts/category/${id}/`,
    PATCH_CONTACTS_PK: id => `contacts/${id}/`,
    POST_CONTACTS_CATEGORIES_LIST: org_pk => `contacts/categories/${org_pk}/`,
    POST_CONTACTS_LIST_ACTION: org_pk => `contacts/list-action/${org_pk}/`,
    POST_CONTACTS_LIST_PK: () => `contacts/`
  },

  CONTRACTORS: {
    DELETE_CONTRACTS_CONTRACTOR: id => `contracts/contractor/${id}/`,
    GET_CONTRACTORS_LIST: pId => `projects/deliverables/${pId}/contractors/`,
    GET_CONTRACTS_CONTRACTOR: id => `contracts/contractor/${id}/`,
    PATCH_CONTRACTS_CONTRACTOR: id => `contracts/contractor/${id}/`
  },

  CONTRACTS: {
    DELETE_CONTRACTS: id => `contracts/${id}/`,
    DELETE_CONTRACTS_MONTH: id => `contracts/month/${id}/`,
    GET_CONTRACTS: orgId => `contracts/${orgId}/`,
    GET_CONTRACTS_CONTRACTOR: id => `contracts/contractor/${id}/`,
    GET_CONTRACTS_CONTRACTOR_LIST: org_pk => `contracts/contractor/${org_pk}/`,
    GET_CONTRACTS_ITEMS: org_pk => `contracts/item/${org_pk}/`,
    GET_CONTRACTS_MONTH_LIST: org_pk => `contracts/month/${org_pk}/`,
    PATCH_CONTRACTS: id => `contracts/${id}/`,
    PATCH_CONTRACTS_ITEM: id => `contracts/item/${id}/`,
    PATCH_CONTRACTS_MONTH: id => `contracts/month/${id}/`,
    POST_CONTRACT_CONTRACTOR_LIST_PK: org_pk =>
      `contracts/contractor/${org_pk}/`,
    POST_CONTRACTOR_LIST_PROJECT: (orgId, pId) =>
      `contracts/contractor/${orgId}/?filter_project=${pId}`,
    POST_CONTRACTS_ITEM_LIST: id => `contracts/item/${id}/`,
    POST_CONTRACTS_LIST: orgId => `contracts/${orgId}/`,
    POST_CONTRACTS_MONTH_GENERATE: (org_pk, team_pk) =>
      `contracts/month/generate/${org_pk}/?team=${team_pk}`,
    POST_CONTRACTS_MONTH_LIST: (org_pk, team_pk) =>
      `contracts/month/${org_pk}/?team=${team_pk}`
  },

  COUNTRIES: {
    GET_COUNTRIES: `organizations/countries/`
  },
  CURRENCIES: {
    GET_CURRENCY_PK: id => `currencies/${id}/`,
    GET_CURRENCIES_DEFAULT: `currencies/default/`,
    GET_CURRENCIES_LIST: `currencies/?page_size=9999999`,
    GET_CURRENCIES_SELECTED: `currencies/?settings=true&is_selected=true`,
    GET_CURRENCIES_NOT_SELECTED: `currencies/?page_size=9999999&settings=true&is_selected=false`,
    POST_CURRENCIES_LIST_ACTION_PK: org_pk =>
      `currencies/list/actions/${org_pk}/`,
    POST_CURRENCY: `currencies/`,
    DELETE_CURRENCY: id => `currencies/${id}/`
  },
  CUSTOMFIELDS: {
    DELETE_CUSTOMFIELD: id => `customfields/field/${id}/`,
    GET_CUSTOMFIELDS_FILTERED_LIST: (org_pk, content_type) =>
      `customfields/field/${org_pk}/?content_type=${content_type}`,
    GET_CUSTOMFIELDS_LIST: org_pk => `customfields/field/${org_pk}/`,
    GET_CUSTOMFIELDS_VALUES: org_pk => `customfields/values/${org_pk}/`,
    PATCH_CUSTOMFIELD: id => `customfields/field/${id}/`,
    POST_CUSTOMFIELD_LIST: org_pk => `customfields/field/${org_pk}/`,
    POST_CUSTOMFIELDS_VALUES: org_pk => `customfields/values/${org_pk}/`
  },

  DIGESTS: {
    DELETE_DIGEST: notification_pk =>
      `digests/inbox/delete/${notification_pk}/`,
    GET_DIGESTS_CONFIG: org_pk => `digests/digest-config/${org_pk}/`,
    GET_DIGESTS_INBOX_ALL: `digests/inbox/all/`,
    GET_DIGESTS_INBOX_UNREAD: `digests/inbox/unread/`,
    MARK_ALL_AS_READ: `digests/inbox/mark-all-as-read/`,
    MARK_ALL_AS_UNREAD: `digests/inbox/mark-all-as-unread/`,
    MARK_AS_READ: notification_pk =>
      `digests/inbox/mark-as-read/${notification_pk}/`,
    MARK_AS_UNREAD: notification_pk =>
      `digests/inbox/mark-as-unread/${notification_pk}/`,
    PATCH_DIGESTS_CONFIG: org_pk => `digests/digest-config/${org_pk}/`
  },

  DISCOUNTS: {
    DELETE_DISCOUNT: discountId => `discounts/${discountId}/`,
    GET_DISCOUNT: discountId => `discounts/${discountId}/`,
    GET_DISCOUNT_LIST: () => `discounts/`,
    PATCH_DISCOUNT: discountId => `discounts/${discountId}/`,
    POST_DISCOUNT: () => `discounts/`
  },

  DOCUMENTS: {
    DELETE_DOCUMENT: id => `documents/${id}/`,
    GET_DOCUMENTS_LIST_PROJECT_PK: project_pk => `documents/${project_pk}/`,
    GET_EXPORT_DOCUMENTS: project_pk => `documents/export/${project_pk}/`,
    PATCH_DOCUMENT: id => `documents/${id}/`,
    POST_DOCUMENT_LIST: project_pk => `documents/${project_pk}/`,
    POST_DOCUMENT_SET_PRICE: project_pk => `documents/set-price/${project_pk}/`,
    POST_GENERATE_DOCUMENTS: project_pk => `documents/generate/${project_pk}/`
  },

  EMAILS: {
    DELETE_EMAIL: id => `emails/${id}/`,
    DELETE_SMTP: id => `emails/smtp/${id}/`,
    GET_EMAILS_LIST_PK: `emails/`,
    GET_LOGS_LIST: `emails/logs/`,
    GET_SMTP_LIST_PK: `emails/smtp/`,
    PATCH_EMAIL: id => `emails/${id}/`,
    PATCH_SMTP: id => `emails/smtp/${id}/`,
    POST_EMAIL_APPLY: id => `emails/${id}/apply/`,
    POST_EMAIL_LIST: `emails/`,
    POST_EMAIL_SEND_TEST: id => `emails/${id}/send-test/`,
    POST_SMTP_LIST: `emails/smtp/`,
    POST_SMTP_SEND_TEST: id => `emails/smtp/${id}/send-test/`
  },

  EVENTS: {
    DELETE_EVENT: id => `events/${id}/`,
    GET_EVENT: id => `events/${id}/`,
    GET_EVENTS_LIST: `events/`,
    PATCH_EVENT: id => `events/${id}/`,
    POST_EVENT: `events/`,
    GET_EVENT_LIST: () => `events/`,
    GET_EVENT_ID: id => `events/${id}/`
  },

  EXPENSES: {
    DELETE_CATEGORIES: id => `categories/${id}/`,
    DELETE_CATEGORY_GROUP: id => `categories/group/${id}/`,
    DELETE_EXPENSE: id => `expenses/category/${id}/`,
    DELETE_EXPENSE_REPORT: id => `expenses/groups/${id}/`,
    EXPANSES_PDF: (orgPk, teamId, expensesId) =>
      `reports/preview/${orgPk}/?team=${teamId}&expense_group=${expensesId}`,
    EXPENSE: id => `expenses/${id}/`,
    EXPENSE_DETAILS: (id, groupId) => `expenses/${id}/${groupId}/`,
    GET_CATEGORIES: id => `categories/?operation=${id}`,
    GET_CATEGORY_GROUP: groupId => `categories/group/${groupId}/`,
    GET_CATEGORY_GROUPS: id =>
      id ? `categories/group/?operation=${id}` : `categories/group/`,
    GET_CATEGORY_TEMPLATES: () => 'categories/templates/',
    GET_CATEGORY_TEMPLATE: templateId => `categories/templates/${templateId}/`,
    GET_EXPENSE: id => `expenses/groups/${id}/`,
    GET_EXPENSES_CATEGORY: id => `expenses/category/${id}/`,
    GET_EXPENSES_CATEGORY_LIST: org_pk => `expenses/category/${org_pk}/`,
    GET_EXPENSES_GENERATE_PDF: id => `expenses/pdf/${id}/`,
    GET_EXPENSES_GROUPS: team_pk => `expenses/groups/${team_pk}/`,
    GET_EXPENSES_GROUPS_LIST: org_pk => `expenses/groups/v2/${org_pk}/`,
    GET_EXPENSES_LIST: `expenses/`,
    GET_EXPENSES_LIST_BY_GROUP: (team_pk, group_pk) =>
      `expenses/${team_pk}/${group_pk}/`,
    GET_EXPENSES_LIST_PK: () => `expenses/`,
    GET_EXPENSES_MY_GROUPS: team_pk => `expenses/my-groups/${team_pk}/`,
    GET_EXPENSES_MY_GROUPS_LIST: (id, orgId = '', query = '', page_size = 20) =>
      `expenses/my-groups/${id}/` +
      '?' +
      queryString({ orguser: orgId, page_size, q: query, team: id }),
    PATCH_CATEGORIES: id => `categories/${id}/`,
    PATCH_CATEGORY_GROUP: id => `categories/group/${id}/`,
    PATCH_EXPENSE: id => `expenses/category/${id}/`,
    PATCH_EXPENSE_FILE: id => `expenses/groups/${id}/`,
    PATCH_EXPENSE_PK: id => `expenses/${id}/`,
    PATCH_EXPENSES_GROUP: id => `expenses/groups/${id}/`,
    POST_CATEGORY_GROUPS: `categories/group/`,
    POST_CATEGORY_TEMPLATE: () => 'categories/templates/',
    POST_CATEGORIES: `categories/`,
    POST_EXPENSE: () => 'expenses/',
    POST_EXPENSES_ACTION: org_pk => `expenses/action/${org_pk}/`,
    POST_EXPENSES_GROUPS: team_pk => `expenses/groups/${team_pk}/`,
    POST_EXPENSES_GROUPS_ACTION: (org_pk, team_pk) =>
      `expenses/groups/list/action/${org_pk}/?team=${team_pk}`,
    POST_EXPENSES_LIST: org_pk => `expenses/category/${org_pk}/`,
    POST_EXPENSES_LIST_PK: org_pk => `expenses/groups/v2/${org_pk}/`,
    POST_EXPENSES_MY_GROUPS: team_pk => `expenses/my-groups/${team_pk}/`,
    POST_GENERATE_TEMPLATE: operationId =>
      `/categories/templates/generate_from_operation/operation/${operationId}/`,
    APPLY_CATEGORY_TEMPLATE: (templateId, operationId) =>
      `categories/templates/${templateId}/apply/operation/${operationId}/`,
    DELETE_CATEGORY_TEMPLATE: templateId =>
      `categories/templates/${templateId}/`,
    PATCH_CATEGORY_TEMPLATE: templateId => `categories/templates/${templateId}/`
  },

  FEES: {
    GET_FEES_LIST: project_pk => `fees/${project_pk}/`,
    GET_FEES_REVISIONS_LIST: fee_id => `fees/revisions/${fee_id}/`,
    GET_FEES_FFNE_ZONES: project_pk => `fees/zones/ffne/load/${project_pk}/`,
    GET_FEES_RULES: `fees/`,
    POST_FEES_LIST: project_pk => `fees/${project_pk}/`,
    POST_FEES_REVISIONS_LIST: fee_id => `fees/revisions/${fee_id}/`,
    POST_FEES_FFNE_ZONES_UPDATE: `fees/zones/ffne/update/`,
    POST_VALIDATE_FFNE: project_pk => `fees/validate_ffne/${project_pk}/`,
    POST_FEES_RULES: `fees/`,
    PATCH_FEE: id => `fees/${id}/`,
    PATCH_FEES_REVISION: id => `fees/revision/${id}/`,
    DELETE_FEE: id => `fees/${id}/`,
    DELETE_FEES_REVISION: id => `fees/revision/${id}/`
  },

  FILES: {
    DELETE_FILE: id => `files/${id}/`,
    DELETE_FOLDER: id => `files/folder/${id}/`,
    DOWNLOAD_FILE: `files/downloads/`,
    GET_FILE: id => `files/${id}/`,
    GET_FILES: () => `files/list/`,
    GET_FILES_FOLDER_LIST: project_pk =>
      project_pk
        ? `files/folder/list/?operation=${project_pk}`
        : `files/folder/list/`,
    GET_FILES_LIST: project_pk =>
      project_pk ? `files/list/?operation=${project_pk}` : `files/list/`,
    PATCH_FILE: id => `files/${id}/`,
    PATCH_FOLDER: id => `files/folder/${id}/`,
    POST_FILES_FOLDER_LIST: `files/folder/list/`,
    POST_FILES_LIST: `files/list/`
  },

  FINANCE: {
    GET_METRICS: () => `metrics/operation_finance/`,
    GET_SALES_STATS: () => `metrics/operation_finance/sales_stats/`
  },

  FLOORS: {
    DELETE_FLOOR: floorId => `floors/${floorId}`,
    GET_FLOOR: floorId => `floors/${floorId}/`,
    GET_FLOORS: () => `floors/`,
    PATCH_FLOOR: floorId => `floors/${floorId}`,
    POST_FLOOR: () => `floors/`
  },

  GOALS: {
    DELETE_GOAL: id => `goals/${id}/`,
    GET_GOALS_LIST: org_pk => `goals/${org_pk}/`,
    PATCH_GOAL_EDIT: id => `goals/${id}/`,
    POST_GOALS_LIST: org_pk => `goals/${org_pk}/`
  },

  HUBSPOT: {
    POST_HUBSPOT_USER: 'hubspot/get_user_token/'
  },

  IMPORTS: {
    DELETE_EXPORT_ID: id => `exports/${id}/`,
    DELETE_IMPORT_ID: id => `imports/${id}/`,
    GET_ALL_EXPORTS: `exports/`,
    GET_COSTS_EXPORTS_LIST_PK: org_pk =>
      `imports/export/${org_pk}?type=10&type=14&type=23&type=29&type=32`,
    GET_DOWNLOAD_FILE: (org_pk, type, team_pk, project_pk) =>
      `imports/import/${org_pk}/?type=${type}&team=${team_pk}&project=${project_pk}`,
    GET_DOWNLOAD_SAMPLE: name => `imports/examples/${name}/`,
    GET_IMPORT: id => `imports/${id}/`,
    GET_IMPORTS_IMPORT: (org_pk, type, pk) =>
      `/imports/import/${org_pk}/?type=${type}&pk=${pk}`,
    GET_IMPORTS_LIST_PK: () => `imports/`,
    GET_INVOICES_EXPORTS_LIST_PK: org_pk =>
      `imports/export/${org_pk}?type=9&type=16&type=10&type=11&type=12&type=13&type=21`,
    GET_EXPORTS_LIST_PK: org_pk => `imports/export/${org_pk}/`,
    POST_CREATE_EXPORT: `exports/`,
    POST_EXPORT_LIST_PK: org_pk => `imports/export/${org_pk}/`,
    POST_IMPORT_CONTINUE: (org_pk, type, pk, team_pk, project_pk) =>
      `/imports/import/${org_pk}/?type=${type}&pk=${pk}&team=${team_pk}&project=${project_pk}`,
    POST_IMPORT_FILE: (org_pk, type, team_pk, project_pk) =>
      `/imports/import/${org_pk}/?type=${type}&team=${team_pk}&project=${project_pk}`,
    POST_IMPORT_MAP_COLUMNS: id => `imports/${id}/map-columns/`,
    RUN_IMPORT_ID: id => `imports/${id}/run/`
  },

  INBOUND_EMAILS: {
    DELETE_INBOUND_EMAIL: id => `inbound_emails/${id}/`,
    GET_INBOUND_EMAILS_LIST: org_pk => `inbound_emails/${org_pk}/`,
    PATCH_INBOUND_EMAIL: id => `inbound_emails/${id}/`,
    POST_INBOUND_EMAILS_LIST: org_pk => `inbound_emails/${org_pk}/`
  },

  INDICATORS: {
    GET_INDICATORS_LIST: () => `indicators/`
  },

  INDEXES: {
    GET_INDEXES: () => `indices/`
  },
  INVITATIONS: {
    GET_INVITATIONS_LIST: org_pk => `invitations/${org_pk}/`,
    POST_ACCEPT_INVITATION: code => `invitations/invitations/accept/${code}/`,
    POST_SET_PASSWORD: code => `invitations/set_password/${code}/`,
    DELETE_INVITATION: id => `invitations/${id}/`
  },
  INVOICES: {
    DELETE_CONTRACT_ITEM: id => `invoices/contract/item/${id}/`,
    DELETE_INVOICE_ITEM_PK: (invoiceId, id) =>
      `/invoices/${invoiceId}/items/${id}/`,
    DELETE_INVOICES: id => `invoices/${id}/`,
    DELETE_INVOICES_FOLLOWUP: id => `invoices/followup/${id}/`,
    DELETE_INVOICES_FOLLOWUP_RULE: id => `invoices/followup/rule/${id}/`,
    GENERATE_SUGGESTION: () => `invoices/generate_suggestions/`,
    GET_ACCOUNTING_DASHBOARD: team_pk =>
      `invoices/accounting-dashboard/${team_pk}/`,
    GET_BILLABLE_SUMMARY: team_pk => `invoices/billable-summary/${team_pk}/`,
    GET_CONTRACT_ITEM: id => `invoices/contract/item/${id}/`,
    GET_CONTRACT_ITEMS: id => `invoices/contract/items/${id}/`,
    GET_INCOME: 'stats/income/',
    GET_INVOICE_ITEMS_PK: invoice_id => `invoices/${invoice_id}/items/`,
    GET_INVOICE_PK: id => `invoices/${id}/`,
    GET_INVOICE_PREVIEW: invoice_id => `invoices/preview/${invoice_id}/`,
    GET_INVOICE_USER_LIST_PK: (org_pk, team_pk) =>
      `invoices/${org_pk}/?team=${team_pk}`,
    GET_INVOICE_WITH_USER_LIST_PK: (org_pk, team_pk) =>
      `invoices/${org_pk}/?team=${team_pk}`,
    GET_INVOICING_METRICS: (team_pk, project_pk) =>
      `invoices/invoicing-metrics/${team_pk}/${project_pk}/`,
    GET_INVOICES_COUNT: () => `invoices/count/`,
    GET_INVOICES_EXPORTS_LIST_PK: org_pk => `imports/export/${org_pk}/`,
    GET_INVOICES_FOLLOWUP: id => `invoices/followup/${id}/`,
    GET_INVOICES_FOLLOWUP_RULES_LIST: org_pk =>
      `invoices/followup/rule/${org_pk}/`,
    GET_INVOICES_FOLLOWUPS: org_pk => `invoices/followup/${org_pk}/`,
    GET_INVOICES_LIST: `invoices/`,
    GET_INVOICES_LIST_BY_STATUS_PK: (org_pk, team_pk) => ({
      draft:
        `invoices/` +
        '?' +
        queryString({
          status: ['not_valid', 'not_valid_auto'],
          team: team_pk,
          org: org_pk
        }),
      to_send:
        `invoices/` +
        '?' +
        queryString({
          status: 'not_sent',
          is_valid: true,
          is_scheduled: false,
          team: team_pk
        }),
      scheduled:
        `invoices/` +
        '?' +
        queryString({ status: 'not_sent', is_scheduled: true, team: team_pk }),
      open:
        `invoices/` +
        '?' +
        queryString({
          status: ['not_paid', 'partial', 'late', 'litigious'],
          team: team_pk
        }),
      closed:
        `invoices/` +
        '?' +
        queryString({ status: ['paid', 'canceled', 'closed'], team: team_pk })
    }),
    GET_INVOICES_LIST_PK: () => `invoices/`,
    GET_INVOICES_METRICS: org_pk => `invoices/metrics/`,
    GET_INVOICES_PERIODS: (org_pk, team_pk) =>
      `invoices/accounting_periods/${org_pk}/?team=${team_pk}`,
    GET_INVOICES_PERIODS_PER_PROJECT: (org_pk, team_pk) =>
      `invoices/accounting_periods_per_project/${org_pk}/?team=${team_pk}`,
    GET_INVOICES_PREVIEW: org_pk => `invoices/preview/${org_pk}/`,
    GET_LAST_POST: orgPk => `posts/last-post/${orgPk}/`,
    GET_OCCUPANCY: 'stats/indicators/occupancy/',
    GET_REVENUE_COMPOSITION_CHARTS: team_pk =>
      `invoices/revenue-composition-charts/${team_pk}/`,
    GET_REVENUE_MONTH_LIST_PK: org_pk => `revenue/month/${org_pk}/`,
    GET_TEMPLATES: () => `invoices/suggestions/`,
    GET_UNPAID_COMPOSITION_CHARTS: team_pk =>
      `invoices/unpaid-composition-charts/${team_pk}/`,
    PATCH_CONTRACT_ITEM: id => `invoices/contract/item/${id}/`,
    PATCH_INVOICE_ITEM: (invoiceId, id) =>
      `/invoices/${invoiceId}/items/${id}/`,
    PATCH_INVOICE_PK: id => `invoices/${id}/`,
    PATCH_INVOICES_FOLLOWUP: id => `invoices/followup/${id}/`,
    PATCH_INVOICES_FOLLOWUP_RULE: id => `invoices/followup/rule/${id}/`,
    POST_FOLLOWUP_SEND: id => `invoices/followup/${id}/send/`,
    POST_GENERATE_AUTO_DRAFT_PK: (org_pk, team_pk) =>
      `invoices/generage-auto-draft-invoices/${org_pk}/?team=${team_pk}`,
    POST_GENERATE_INVOICE_ITEM: invoiceId =>
      `invoices/automation/${invoiceId}/`,
    POST_INVOICE_ITEM: invoice_id => `invoices/${invoice_id}/items/`,
    POST_INVOICE_PREVIEW: org_pk => `invoices/preview/${org_pk}/`,
    POST_INVOICES: () => 'invoices/',
    POST_INVOICES_ACTION: org_pk => `invoices/action/${org_pk}/`,
    POST_INVOICES_CONTRACT_ITEM_GENERATE: id =>
      `invoices/contract/item/generate/${id}/`,
    POST_INVOICES_FOLLOWUP_ACTION: (org_pk, team_pk) =>
      `invoices/followup/action/?org=${org_pk}&team=${team_pk}`,
    POST_INVOICES_FOLLOWUP_GENERATE: (org_pk, team_pk) =>
      `invoices/followup/${org_pk}/generate/?team=${team_pk}`,
    POST_INVOICES_FOLLOWUP_RULES_LIST: org_pk =>
      `invoices/followup/rule/${org_pk}/`,
    POST_INVOICES_SYNC_CONTRACT_INVOICE_PROGRESS: id =>
      `/invoices/${id}/sync-contract-invoice-progress/`,
    POST_INVOICES_TEAM_ACTION: (org_pk, team_pk) =>
      `invoices/action/${org_pk}/?team=${team_pk}`,
    POST_INVOICE_USER_LIST_PK: (org_pk, team_pk) =>
      `invoices/${org_pk}/?team=${team_pk}`,
    POST_NOTIFY_CONTRACTORS: id => `invoices/${id}/notify-contractors/`,
    POST_REMOVE_FEE_PROJECT_TITLES: id =>
      `invoices/${id}/remove-fee-project-titles/`,
    POST_RESET_ORDERS: id => `invoices/${id}/reset-orders/`,
    GET_INVOICE_LIST: () => 'invoices/',
    GET_INVOICE_ID: id => `invoices/${id}/`,
    POST_INVOICE_LIST: () => 'invoices/'
  },

  INVESTORS: {
    DELETE_INVESTOR: investorId => `investors/${investorId}/`,
    DELETE_SHARE: shareId => `investors/share/${shareId}/`,
    GET_INVENTOR_TASKS: () => `tasks/`,
    GET_INVESTOR: investorId => `investors/${investorId}/`,
    GET_INVESTORS: () => `investors/`,
    GET_SHARE: shareId => `investors/share/${shareId}/`,
    GET_SHARES: () => `investors/share/`,
    PATCH_INVESTOR: investorId => `investors/${investorId}/`,
    PATCH_SHARE: shareId => `investors/share/${shareId}/`,
    POST_INVESTOR: () => `investors/`,
    POST_SHARE: () => `investors/share/`
  },

  JOBS: {
    DELETE_JOBS_PK: id => `jobs/${id}/`,
    DELETE_JOBS_INVOICES_ITEMS_PK: id => `jobs/invoices/items/${id}/`,
    DELETE_JOBS_INVOICES_PK: id => `jobs/invoices/${id}/`,
    GET_JOBS_INVOICES_ITEMS_LIST_PK: org_pk => `jobs/invoices/items/${org_pk}/`,
    GET_JOBS_INVOICES_LIST_PK: org_pk => `jobs/invoices/${org_pk}/`,
    GET_JOBS_INVOICES_PK: id => `jobs/invoices/${id}/`,
    GET_JOB_PK: id => `jobs/${id}/`,
    GET_JOBS_LIST_PK: org_pk => `jobs/${org_pk}/`,
    PATCH_JOB_PK: id => `jobs/${id}/`,
    PATCH_JOBS_INVOICES_ITEMS_PK: id => `jobs/invoices/items/${id}/`,
    PATCH_JOBS_INVOICES_PK: id => `jobs/invoices/${id}/`,
    POST_JOBS_INVOICES_LIST_PK: org_pk => `jobs/invoices/${org_pk}/`,
    POST_JOBS_INVOICES__ITEMS_LIST_TEAM_PK: (org_pk, team_pk) =>
      `jobs/invoices/items/${org_pk}/?team=${team_pk}`,
    POST_JOBS__LIST_PK: org_pk => `jobs/${org_pk}/`
  },

  LANDLORDS: {
    DELETE_LANDLORD_BY_ID: id => `landlords/${id}/`,
    DELETE_LANDLORD: id => `landlords/${id}/`,
    GET_ALL_LANDLORDS: `landlords/`,
    GET_LANDLORD_BY_ID: id => `landlords/${id}/`,
    GET_LANDLORD_LEASES: landlordId => `leases/?landlord=${landlordId}`,
    GET_LANDLORD_MANDATES: landlordId => `mandates/?landlord=${landlordId}`,
    GET_LANDLORD_TASKS: () => `tasks/`,
    GET_LANDLORDS_LIST: () => `landlords/`,
    GET_LANDLORDS_LIST_BY_PROJECT: id => `landlords/?operations=${id}`,
    GET_PROJECT_LANDLORDS: id => `landlords/?operations=${id}`,
    PATCH_LANDLORD_BY_ID: id => `landlords/${id}/`,
    PATCH_LANDLORD: id => `landlords/${id}/`,
    PATCH_LANDLORD_FILE: id => `landlords/${id}/`,
    POST_LANDLORD: () => `landlords/`
  },

  LANGUAGES: {
    GET_LANGUAGES: `organizations/languages/`
  },

  LEASES: {
    DELETE_LEASE_ARTICLE: (leaseId, articleId) =>
      `leases/${leaseId}/articles/${articleId}/`,
    DELETE_LEASE_PERIOD: (leaseId, periodId) =>
      `leases/${leaseId}/periods/${periodId}/`,
    GET_LEASE: leaseId => `leases/${leaseId}/`,
    GET_LEASE_ARTICLES: leaseId => `/leases/${leaseId}/articles/`,
    GET_LEASE_PERIODS: leaseId => `leases/${leaseId}/periods/`,
    GET_LEASE_SCHEDULE: leaseId => `/leases/${leaseId}/schedule/`,
    GET_LEASES_LIST: operationId =>
      operationId ? `leases/?operation=${operationId}` : 'leases/',
    GET_LEASES_PERIODS: () => `/leases/periods/all/`,
    PATCH_LEASE_ARTICLE: (leaseId, articleId) =>
      `leases/${leaseId}/articles/${articleId}/`,
    PATCH_LEASE_PERIOD: (leaseId, periodId) =>
      `leases/${leaseId}/periods/${periodId}/`,
    POST_LEASE: () => `leases/`,
    POST_LEASE_ARTICLES: leaseId => `/leases/${leaseId}/articles/`
  },

  LOTS: {
    DELETE_LOT: lotId => `lots/${lotId}/`,
    DELETE_LOT_GROUP: lotId => `lots/groups/${lotId}/`,
    GET_ALL_LOTS: `lots/`,
    GET_LOT: lotId => `lots/${lotId}/`,
    GET_LOT_BUDGETS: (lotId, year) => `lots/${lotId}/budget/${year}/`,
    GET_LOT_GROUP: lotGroupId => `lots/groups/${lotGroupId}/`,
    GET_LOT_GROUPS: () => `lots/groups/`,
    GET_LOT_PK: id => `lots/${id}/`,
    GET_LOTS: () => `lots/`,
    GET_LOTS_LIST: () => `lots/`,
    PATCH_LOT: lotId => `lots/${lotId}/`,
    PATCH_LOT_GROUP: lotId => `lots/groups/${lotId}/`,
    POST_LOT: () => `lots/`,
    POST_LOT_GROUP: () => `lots/groups/`
  },

  MANDATES: {
    DELETE_MANDATES: id => `mandates/${id}/`,
    GET_MANDATE: mandateId => `mandates/${mandateId}/`,
    GET_MANDATES: () => `mandates/`,
    PATCH_MANDATES: id => `mandates/${id}/`,
    POST_MANDATES: `mandates/`
  },

  METERS: {
    DELETE_METER: meterId => `meters/${meterId}/`,
    DELETE_METER_READING: readingId => `meters/revision/${readingId}/`,
    GET_METER: meterId => `meters/${meterId}/`,
    GET_METER_READING: readingId => `meters/revision/${readingId}/`,
    GET_METER_READINGS: () => `meters/revision/`,
    GET_METERS: () => `meters/`,
    PATCH_METER: meterId => `meters/${meterId}/`,
    PATCH_METER_READING: readingId => `meters/revision/${readingId}/`,
    POST_METER: () => `meters/`,
    POST_METER_READINGS: () => `meters/revision/`
  },

  METRICS: {
    GET_MANAGER_REVENUE: () => `metrics/manager/revenue/`
  },

  MILESTONES: {
    DELETE_MILESTONES: id => `milestones/${id}/`,
    GET_MILESTONE: id => `milestones/${id}/`,
    GET_MILESTONES: (orgId, pId) =>
      pId ? `milestones/${orgId}/?project=${pId}` : `milestones/${orgId}/`,
    PATCH_MILESTONE: id => `milestones/${id}/`,
    POST_MILESTONE: (orgId, pId) => `milestones/${orgId}/?project=${pId}`
  },

  MORTGAGES: {
    DELETE_MORTGAGE: mortgageId => `mortgages/${mortgageId}/`,
    GET_MORTGAGE: mortgageId => `mortgages/${mortgageId}/`,
    GET_MORTGAGES: () => `mortgages/`,
    PATCH_MORTGAGE: mortgageId => `mortgages/${mortgageId}/`,
    POST_MORTGAGE: () => `mortgages/`
  },

  NOTES: {
    DELETE_NOTES: id => `notes/${id}/`,
    GET_NOTES_LIST: id => `notes/${id}/`,
    PATCH_NOTES: id => `notes/${id}/`,
    POST_NOTES_LIST: id => `notes/${id}/`
  },

  OCR: {
    EXPENSE: `/ocr/extract-data/`,
    INVOICE: () => `/OCR/extract_data/invoices/`
  },

  OPPORTUNITIES: {
    GET_PROJECTS_LEADS2: org_pk => `projects/leads2/${org_pk}/`
  },

  ORGANIZATIONS: {
    GET_ADMIN_DASHBOARD_METRICS: `organizations/admin-dashboard-metrics/`,
    GET_ADMIN_DASHBOARD_METRICS_BY_TEAM: team_pk =>
      `organizations/admin-dashboard-metrics/${team_pk}/`,
    GET_BUDGETS_USERS: team_pk => `organizations/budgets-users/${team_pk}/`,
    GET_DASHBOARD_METRICS: `organizations/dashboard-metrics/`,
    GET_MEMBERSHIP: `organizations/membership/`,
    GET_ONBOARDING: () => 'organizations/onboarding/',
    GET_ORGANIZATION: id => `organizations/${id}/`,
    GET_ORGANIZATION_METRICS: org_pk => `organizations/metrics/${org_pk}/`,
    GET_PERIODS: `organizations/periods/`,
    PATCH_ORGANIZATION: id => `organizations/${id}/`,
    PATCH_TRANSFER_OWNERSHIP: id => `organizations/transfer-ownership/${id}/`
  },

  ORG_USER: {
    DELETE_ORG_USER: id => `orgusers/${id}/`,
    DELETE_ORG_USER_TEAM: (id, teamId) => `orgusers/${id}/?team=${teamId}`,
    EXPORT_ORG_USER: id => `orgusers/export/${id}/`,
    GET_ALL_ORG_USERS: () => 'orgusers/',
    GET_MY_ORG_USER: () => 'orgusers/me/',
    GET_ONBOARDING: () => 'organizations/onboarding/',
    GET_ORGANIZATIONS: 'organizations/membership/',
    GET_ORG_USER: (user_pk, team_pk) => `orgusers/${user_pk}/?team=${team_pk}`,
    GET_ORG_USER_COUNT: id => `orgusers/count/${id}/`,
    GET_ORG_USER_LIST: (orgId, teamId, projectId) =>
      `orgusers/${orgId}/?team=${teamId}&all_users=true&exclude_archived=true&page_size=9999&for_project=${projectId}`,
    GET_ORG_USER_LIST_GLOBAL: (orgId, teamId) =>
      `orgusers/${orgId}/?team=${teamId}`,
    GET_ORG_USER_PK: () => `orgusers/`,
    GET_ORG_USERS: id => `orgusers/${id}/`,
    GET_USER: () => 'orgusers/me/',
    GET_VALIDATION_METRICS: 'orgusers/validation_metrics/',
    POST_ORGANIZATION: 'organizations/',
    POST_ORG_USER_INVITE: `invitations/`,
    POST_ORGUSER_LIST: org_pk => `orgusers/${org_pk}/?is_v4=true`,
    POST_ORG_USER_LIST: (org_pk, team_pk) =>
      `orgusers/${org_pk}/?team=${team_pk}`,
    UPDATE_ORG_USER: userId => `orgusers/${userId}/`
  },

  PAYMENTS: {
    DELETE_PAYMENT_PK: id => `payments/${id}/`,
    GET_INVOICE_PAYMENTS: invoiceId =>
      `/payments/invoice-payment/?invoice=${invoiceId}`,
    GET_PAYMENTS_LIST: 'payments/',
    GET_PAYMENT_PK: id => `payments/${id}/`,
    GET_PEYMENTS_LIST_PK: () => `payments/`,
    PATCH_PAYMENT_INVOIC_EPK: id => `payments/invoice/${id}/`,
    PATCH_PAYMENT_PK: id => `payments/${id}/`,
    POST_PAYMENT: invoiceId => `payments/?invoice=${invoiceId}`,
    POST_PAYMENT_PK: `payments/`
  },

  PERMISSIONS: {
    DELETE_PERMISSION: id => `permissions/${id}/`,
    GET_PERMISSION: id => `permissions/${id}/`,
    GET_PERMISSION_DETAILS: id => `permissions/${id}/permissions_detail/`,
    GET_PERMISSIONS_LIST: id => `permissions/${id}/`,
    GET_PERMISSIONS_MAP: 'permissions/map/',
    PATCH_PERMISSION: id => `permissions/${id}/`,
    POST_PERMISSIONS_LIST: org_pk => `permissions/${org_pk}/`
  },

  PHASES: {
    GET_PHASES_PK: id => `phases/${id}/`
  },

  PIPELINES: {
    DELETE_PIPELINES_STAGE: id => `pipelines/stage/${id}/`,
    GET_PIPELINES_LIST: org_pk => `pipelines/${org_pk}/`,
    GET_PIPELINES_STAGE_LIST: org_pk => `pipelines/stage/${org_pk}/`,
    PATCH_PIPELINES_STAGE: id => `pipelines/stage/${id}/`,
    POST_PIPELINES_STAGE_LIST: org_pk => `pipelines/stage/${org_pk}/`
  },

  PLANS: {
    DELETE_PLAN: id => `plans/${id}/`,
    GET_EXPORT_PLANS: project_pk => `plans/export/${project_pk}/`,
    GET_PLANS_LIST_PROJECT_PK: project_pk => `plans/${project_pk}/`,
    PATCH_PLAN: id => `plans/${id}/`,
    POST_PLAN_LIST_ACTION: project_pk => `plans/list-action/${project_pk}/`,
    POST_PLAN_LIST: project_pk => `plans/${project_pk}/`
  },

  POSTS: {
    DELETE_POSTS: id => `posts/${id}/`,
    DELETE_POST_IMAGE: id => `posts/image/${id}/`,
    DELETE_POSTS_LIKE: id => `posts/like/${id}/`,
    GET_LAST_POST: orgPk => `posts/last-post/${orgPk}/`,
    GET_POSTS: id => `posts/${id}/`,
    GET_POSTS_IMAGE_LIST: id => `posts/image/${id}/`,
    GET_POSTS_LIST: () => `posts/`,
    GET_POSTS_LIKE_LIST: () => `posts/like/`,
    GET_POSTS_TAGS: () => `posts/tags/`,
    GET_POSTS_POST_COMMENTS: id => `posts/post/comments/${id}/`,
    PATCH_POSTS: id => `posts/${id}/`,
    PATCH_POST_IMAGE: id => `posts/image/${id}/`,
    POST_POST_IMAGE: org_pk => `posts/image/${org_pk}/`,
    POST_POSTS_LIKE_LIST: () => `posts/like/`,
    POST_POSTS_LIST: () => `posts/`,
    POST_POST_ALBUM: () => `posts/album/`
  },

  PROFILES: {
    DELETE_PROFILES_USER: `profiles/user/`,
    GET_ONBOARDING_PROGRESS: orgId => `profiles/onboarding/${orgId}/`,
    GET_PROFILES_PROFILE: `profiles/profile/`,
    GET_PROFILES_USER: `profiles/user/`,
    PATCH_PROFILES_PROFILE: `profiles/profile/`,
    PATCH_PROFILES_USER: `profiles/user/`,
    POST_ACTIVATE_2FA: 'profiles/activate_2fa/',
    POST_DISABLE_2FA: 'profiles/disable_2fa/',
    POST_INVITE_USER: 'invitations/',
    POST_OBTAIN_TOKEN: 'profiles/obtain_token/',
    POST_UNVERIFY_2FA: 'profiles/unverify_2fa/',
    POST_VERIFY_2FA: 'profiles/verify_2fa/'
  },

  PROJECT: {
    GET_DELIVERABLES_CONTRACTORS: project_pk =>
      `projects/deliverables/${project_pk}/contractors/`,
    POST_PROJECTS_FAVORITE: project_pk => `projects/favorite/${project_pk}/`
  },

  PROJECTS: {
    DELETE_PROJECT: id => `operations/${id}/`,
    DELETE_PROJECT_TAG_GROUP: id => `projects/tags/groups/${id}/`,
    DELETE_PROJECT_USERS_PK: id => `operations/users/${id}/`,
    DELETE_PROJECTS_TAGS_PK: id => `projects/tags/${id}/`,
    DELETE_PROJECTS_USER_BULK: org_pk =>
      `projects/users/bulk/delete/${org_pk}/`,
    GET_PROJECT_LIST_ACCESS: `operations/list/access/`,
    GET_PROJECT_GROUPS_BY_ORG: orgId => `projects/tags/groups/${orgId}/`,
    GET_PROJECT_LEASE_ARTICLES: operationId =>
      `/operations/${operationId}/lease_articles/`,
    GET_PROJECT_LIST_PK: `operations/`,
    GET_PROJECT_LIST_SIGNED: `operations/?signed=true&page_size=9999`,
    GET_PROJECT_ONBOARDING: id => `operations/${id}/onboarding/`,
    GET_PROJECT_PK: id => `operations/${id}/`,
    GET_PROJECT_SUMMARY_CHART: orgPk =>
      `projects/project_summary_chart/${orgPk}/`,
    GET_PROJECT_TAGS_LIST: id => `operations/tags/${id}/`,
    GET_PROJECT_TIMELINE: orgPk => `projects/project_timeline/${orgPk}/`,
    GET_PROJECT_USER_LIST: `operations/users/list/`,
    GET_PROJECTS_USER: 'operations/users/',
    GET_PROJECTS_AVAILABLE_CLIENT: id => `operations/available-clients/${id}/`,
    GET_PROJECTS_DASHBOARD: orgPk => `projects/projects-dashboard/${orgPk}/`,
    GET_PROJECTS_EXPORT: org_pk => `projects/export/${org_pk}/`,
    GET_PROJECTS_USER_LIST: id => `operations/users/?operation=${id}`,
    GET_PROJECTS_WITH_ALERTS: orgPk =>
      `projects/projects_with_alerts/${orgPk}/`,
    PATCH_PROJECT_PK: id => `operations/${id}/`,
    PATCH_PROJECT_TAG_GROUP: id => `operations/tags/groups/${id}/`,
    PATCH_PROJECT_USERS_PK: id => `operations/users/${id}/`,
    PATCH_PROJECTS_TAGS_PK: id => `operations/tags/${id}/`,
    POST_PROJECT_ACTION: org_pk => `operations/actions/${org_pk}/`,
    POST_PROJECT_LIST_PK: `operations/`,
    POST_PROJECT_TAGS_LIST: id => `operations/tags/${id}/`,
    POST_PROJECT_TAG_GROUPS_LIST: org_pk => `operations/tags/groups/${org_pk}/`,
    POST_PROJECT_USERS: `operations/users/`,
    POST_PROJECT_USERS_LIST_ACTION: (org_pk, team_pk) =>
      `operations/users/list/action/?org=${org_pk}&team=${team_pk}`,
    POST_PROJECTS_USER_BULK_ADD: org_pk => `projects/users/bulk/add/${org_pk}/`,
    POST_PROJECT_ACTIONS_PK: (id, action) =>
      `operations/actions/${id}/?action=${action}/`
  },

  INTEGRATIONS: {
    DROPBOX_AUTH: 'integrations/dropbox/auth/',
    DROPBOX_CALLBACK: 'integrations/dropbox/callback/',
    DROPBOX_REVOKE: 'integrations/dropbox/revoke/'
  },

  PUBLIC_INDICES: {
    GET_MARKET_DATA: `indices/prices/`,
    GET_PUBLIC_INDICES_LIST: `public_indices/list/`
  },

  QUICKBOOKS: {
    GET_DOWNLOAD_SAGE: 'quickbooks/download/sage/',
    GET_QUICKBOOKS_OAUTH: (org_pk, team_pk) =>
      `quickbooks/oauth/${org_pk}/${team_pk}/`,
    GET_QUICKBOOKS_REVOKE: (org_pk, team_pk) =>
      `quickbooks/revoke/${org_pk}/${team_pk}/`
  },
  REPORTS: {
    GET_REPORTS: proposalId => `reports/${proposalId}/`,
    GET_REPORTS_LIST: operationPk => `reports/list/${operationPk}/`,
    GET_REPORTS_VARIABLES: id => `reports/variables/${id}/`,
    GET_REPORTS_TEMPLATE: reportId => `reports/templates/${reportId}/`,
    GET_REPORTS_TEMPLATE_VARIABLES: reportId =>
      `reports/templates/variables/${reportId}/`,
    GET_REPORTS_TEMPLATES_LIST: teamPk => `reports/templates/${teamPk}/`,
    GET_REPORTS_TEMPLATES_LIST_PUBLIC: teamPk => `reports/templates/${teamPk}/`,
    GET_REPORTS_EXPORT: 'reports/export/',
    POST_REPORTS_PREVIEW: (orgPk, proposalId) =>
      `reports/preview/${orgPk}/?pk=${proposalId}`,
    POST_REPORTS_PREVIEW_BY_QUERY: `reports/preview/`,
    POST_REPORTS_PREVIEW_ORG: org_pk => `reports/preview/${org_pk}`,
    POST_REPORTS_PREVIEW_TEMPLATE: (orgPk, templateId) =>
      `reports/preview/?org=${orgPk}&template=${templateId}`,
    POST_REPORTS_LIST: (orgPk, projectId) =>
      `reports/${orgPk}/?project=${projectId}`,
    POST_GENERATE_REPORT: org_pk => `reports/generate/${org_pk}/`,
    POST_REPORTS_TEMPLATES_LIST: teamPk => `reports/templates/${teamPk}/`,
    POST_REPORTS_TEMPLATES_DUPLICATE: id =>
      `reports/templates/duplicate/${id}/`,
    PATCH_REPORT: id => `reports/${id}/`,
    PATCH_REPORT_TEMPLATE: id => `reports/templates/${id}/`,
    DELETE_REPORT: id => `reports/${id}/`,
    DELETE_REPORT_TEMPLATE: id => `reports/templates/${id}/`,
    EXPLORER: `reports/explorer/`
  },
  ROLES: {
    ALL_ROLES: id => `roles/${id}/`,
    DELETE_ROLES: id => `roles/${id}/`,
    GET_PROJECT_ROLES: (org_pk, project_pk) =>
      `roles/project/${org_pk}/?project=${project_pk}`,
    GET_TEAM_ROLES: (org_pk, team_pk) => `roles/${org_pk}/?team=${team_pk}`,
    PATCH_ROLES: id => `roles/${id}/`,
    PATCH_ROLES_PROJECT: id => `roles/project/${id}/`,
    POST_TEAM_ROLES: (org_pk, team_pk) => `roles/${org_pk}/?team=${team_pk}`,
    ROLE: id => `roles/${id}/`
  },
  SIMULATIONS: {
    DELETE_SIMULATION: id => `simulations/${id}/`,
    GET_SIMULATION: id => `simulations/${id}/`,
    GET_SIMULATIONS: () => `simulations/`,
    PATCH_SIMULATION: id => `simulations/${id}/`,
    POST_SIMULATION: () => `simulations/`,
    GET_ADJUSTMENTS: id => `simulations/${id}/adjustments/`,
    GET_ADJUSTMENT: (simulationId, adjustmentId) =>
      `simulations/${simulationId}/adjustments/${adjustmentId}/`,
    POST_ADJUSTMENTS: simulationId =>
      `simulations/${simulationId}/adjustments/`,
    PATCH_ADJUSTMENT: (simulationId, adjustmentId) =>
      `simulations/${simulationId}/adjustments/${adjustmentId}/`,
    DELETE_ADJUSTMENT: (simulationId, adjustmentId) =>
      `simulations/${simulationId}/adjustments/${adjustmentId}/`,
    COPY_SIMULATION: id => `simulations/${id}/copy/`
  },
  STATS: {
    GET_INVOICING_PLANNING: 'stats/invoicing_planning/',
    GET_INCOME: 'stats/income/',
    GET_OCCUPANCY: 'stats/indicators/occupancy/',
    RENTROLL: `stats/rentroll/`
  },

  STYLEGUIDES: {
    DELETE_STYLEGUIDE: id => `styleguides/${id}/`,
    GET_STYLEGUIDE: id => `styleguides/${id}/`,
    GET_STYLEGUIDES_FONTS: `styleguides/fonts/`,
    GET_STYLEGUIDES_PK: `styleguides/`,
    PATCH_STYLEGUIDE: id => `styleguides/${id}/`,
    POST_DUPLICATE_STYLEGUIDE: (org_pk, id) =>
      `styleguides/duplicate/${org_pk}/?pk=${id}`,
    POST_STYLEGUIDE: `styleguides/`
  },

  SUPPLIERS: {
    DELETE_CONTRACT: contractId => `suppliers/contract/${contractId}/`,
    DELETE_SUPPLIER: supplierId => `suppliers/${supplierId}/`,
    GET_CONTRACT: contractId => `suppliers/contract/${contractId}/`,
    GET_CONTRACTS: () => `suppliers/contract/`,
    GET_SUPPLIER: supplierId => `suppliers/${supplierId}/`,
    GET_SUPPLIER_CONTRACTS: supplierId =>
      `suppliers/contract/?supplier=${supplierId}`,
    GET_SUPPLIER_EXPENSES: supplierId => `expenses/?supplier=${supplierId}`,
    GET_SUPPLIERS_LIST: () => `suppliers/`,
    GET_SUPPLIERS_LIST_BY_PROJECT: id =>
      `suppliers/?page_size=9999&operations=${id}`,
    PATCH_CONTRACT: contractId => `suppliers/contract/${contractId}/`,
    PATCH_SUPPLIER: supplierId => `suppliers/${supplierId}/`,
    POST_CONTRACT: () => `suppliers/contract/`,
    POST_SUPPLIER: () => `suppliers/`
  },

  TAGS: {
    DELETE_TAG: id => `tags/${id}/`,
    GET_TAGS_LIST: org_pk => `tags/${org_pk}/`,
    PATCH_TAG_EDIT: id => `tags/${id}/`,
    POST_TAGS_LIST: org_pk => `tags/${org_pk}/`
  },

  TASKS: {
    CREATE_TASK: () => `tasks/`,
    DELETE_TASKS: id => `tasks/${id}/`,
    GET_TASK: id => `tasks/${id}/`,
    GET_TASKS_LABEL_LIST: id => `tasks/label/${id}/`,
    GET_TASKS_LIST: `tasks/`,
    GET_TASKS_LISTS_LIST: () => `tasks/lists/`,
    PATCH_TASKS: id => `tasks/${id}/`,
    GET_TASK_LIST: () => 'tasks/',
    POST_TASKS_LIST: () => `tasks/`,
    POST_TASKS_LOG_TAKS: id => `tasks/log-tasks/${id}/`
  },

  TEAMS: {
    ADD_ORGUSER_TO_TEAM: org_pk => `teams/users/bulk/add/${org_pk}/`,
    GET_TEAMS_AVAILABILITY_ROLE: team_pk =>
      `teams/availability/roles/?teams=${team_pk}`,
    GET_TEAMS_AVAILABILITY_ROLES: `teams/availability/roles/`,
    GET_TEAMS_AVAILABILITY_TEAM: team_pk =>
      `teams/availability/team/?teams=${team_pk}`,
    GET_TEAMS_AVAILABILITY_USERS: `teams/availability/users/`,
    GET_TEAMS_LIST: org_pk => `teams/${org_pk}/`,
    GET_TEAMS_LIST_ACCESS: `teams/list/access/`,
    GET_TEAMS_ROLE_CHART: team_pk => `teams/role_chart/${team_pk}/`,
    GET_TEAMS_SUMMARY: team_pk => `teams/team-summary/${team_pk}/`,
    GET_TEAM: id => `teams/${id}/`,
    GET_TEAMS_USERS_LIST: team_pk => `teams/users/${team_pk}/`,
    GET_TEAMS_USER_LIST: `teams/users/list/`,
    PATCH_TEAM: id => `teams/${id}/`,
    PATCH_TEAM_USERS: id => `teams/users/${id}/`,
    POST_TEAMS_LIST: org_pk => `teams/${org_pk}/`,
    REMOVE_ORGUSER_FROM_TEAM: org_pk => `teams/users/bulk/delete/${org_pk}/`
  },

  TENANTS: {
    DELETE_TENANT_BY_ID: id => `tenants/${id}/`,
    GET_TENANT_BY_ID: id => `tenants/${id}/`,
    GET_TENANT_INVOICES: tenantId => `invoices/?tenant=${tenantId}`,
    GET_TENANT_LEASES: tenantId => `leases/?tenant=${tenantId}`,
    GET_TENANT_PAYMENTS: tenantId => `payments/?tenant=${tenantId}`,
    GET_TENANTS_LIST: () => `tenants/`,
    GET_TENANTS_LIST_BY_PROJECT: id => `tenants/?operations=${id}`,
    PATCH_TENANT_BY_ID: id => `tenants/${id}/`,
    PATCH_TENANT_FILE: id => `tenants/${id}/`,
    POST_TENANT: () => `tenants/`
  },

  TICKETS: {
    DELETE_TICKET: ticketId => `tickets/${ticketId}/`,
    GET_TICKET: ticketId => `tickets/${ticketId}/`,
    GET_TICKETS: () => `tickets/`,
    PATCH_TICKET: ticketId => `tickets/${ticketId}/`,
    POST_TICKET: () => `tickets/`,
    POST_TICKETS_ACTION: org_pk => `tickets/action/${org_pk}/`
  },

  TRIPS: {
    DELETE_TRIP: id => `trips/${id}/`,
    GET_TRIPS_LIST: org_pk => `trips/${org_pk}/`,
    PATCH_TRIP: id => `trips/${id}/`,
    POST_TRIPS_LIST: (org_pk, team_pk) => `trips/${org_pk}/?team=${team_pk}`
  },

  VISITS: {
    DELETE_VISIT: visitId => `visits/${visitId}/`,
    GET_VISIT: visitId => `visits/${visitId}/`,
    GET_VISITS: () => `visits/`,
    GET_VISITS_LIST: `visits/`,
    PATCH_VISIT: visitId => `visits/${visitId}/`,
    POST_VISIT: () => `visits/`
  },
  ADMIN: {
    GET_ADMIN_DASHBOARD: () => `organizations/accounts/`,
    GET_ORGANIZATION_BY_ID: id => `organizations/accounts/${id}/`
  }
}

export default API_URL
